<template>
  <div v-show="isShow">
    <div class="content">
      <div class="info">
        <div class="video">
          <div class="videos" id="video-container"></div>
        </div>
      </div>
    </div>
    <van-popup v-model="networkAnomaly" :close-on-click-overlay="false">
      <div class="yichang">
        <p class="title">温馨提示</p>
        <div class="info">
          <p>网络已断开</p>
          <p>请退出重新打开...</p>
        </div>
        <p class="kown" @click="tuichu">我知道了</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getToken } from '../../common/api'
import { WebView } from '../../common/app'
import EZUIKit from 'ezuikit-js'
import Utils from '../../util/index'
export default {
  data() {
    return {
      url: '', //地址
      accessToken: '', //萤石云token
      timing: 0,
      networkAnomaly: false,
      timer: null,
      isShow: false,
    }
  },
  created() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      console.log('Safari')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('进入ios')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else {
      console.log('进入安卓')
      this.isShow = true
    }
  },
  mounted() {
    let that = this
    WebView.getPidAndAccessShiP = this.getPidAndAccessShiP
    console.log('数据', this.getPidAndAccessid)
    Utils.$on('getToken', () => {
      that.getToken()
    })
  },
  methods: {
    getPidAndAccessShiP(pidAndAccessId) {
      console.log('传过来的数据', pidAndAccessId)
      // let all = pidAndAccessId.split('And')
      // const accessId = all[1]
      // const pid = all[0]
      // this.$store.commit('SET_ACCESS_ID', accessId)
      // this.$store.commit('SET_P_ID', pid)
      this.url = pidAndAccessId
      setTimeout(() => {
        this.getToken()
      }, 500)
    },

    // 视频初始化
    async getToken() {
      // 发送 POST 请求
      console.log('进来')

      const res = await getToken()
      if (res.data.code != 200) return
      this.accessToken = res.data.data.accessToken
      console.log(this.accessToken)
      //视频初始化
      console.log('数据999999', res)
      console.log(this.url, 'this.urlthis.url')
      this.player = await new EZUIKit.EZUIKitPlayer({
        autoplay: true, //自动播放
        id: 'video-container', // 视频容器ID
        accessToken: this.accessToken,
        url: this.url,
        audio: 0,
      })
      this.timer = setInterval(() => {
        this.timing += 60000
        if (this.timing >= 600000) {
          this.stopVideo()
          console.log('结束播放')
          this.networkAnomaly = true
          clearInterval(this.timer)
          return
        }
      }, 60000)
    },
    // 停止播放
    stopVideo() {
      this.player.stop()
    },
    // 退出
    tuichu() {
      console.log('点击关闭')
      window.location.href = 'uniwebview://closeFeed?test=closeFeed'
    },
    close() {
      console.log('点击关闭')
      window.location.href = 'uniwebview://closeFeed?test=closeFeed'
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
  width: 100vw;
  // background: #fbe5ba;
  .info {
    width: 100%;
    // height: 11rem;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // margin-top: 0.2rem;
    .video {
      // width: 8rem;
      width: 100vw;

      // height: 8rem;
      height: 100vh;
      background: #000;
      overflow: hidden;
      /deep/ .videos {
        // width: 8rem;
        width: 100vw;
        // height: 8rem;
        height: 100vh;
      }
    }
  }
}

.van-popup {
  background: none;
}

.yichang {
  width: 5rem;
  height: 5.6rem;
  background: #fde3bc;
  border-radius: 0.2rem;
  overflow: hidden;
  .title {
    font-size: 0.57rem;
    color: #945614;
    text-align: center;
    margin-top: 0.6rem;
  }
  .info {
    font-size: 0.4rem;
    color: #945614;
    margin-top: 0.5rem;
    text-align: center;
    p {
      margin-top: 0.1rem;
    }
  }
  .kown {
    width: 3rem;
    height: 0.87rem;
    background: #945614;
    color: #fff;
    margin: auto;
    margin-top: 0.8rem;
    line-height: 0.86rem;
    text-align: center;
    border-radius: 1rem;
    font-size: 0.4rem;
  }
}
</style>
